* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background: #202225;
    color: #fafafa;
    font-family: Roboto;
}



.room-header {
    cursor: pointer;
    padding: 1em;
}

.person {
    margin: 1em;
    background: #2F3136;
    border-radius: 0.2em;
    display: flex;
    flex-direction: column;
}

.person.big {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.person.big .video {
    flex-grow: 1;
    overflow: hidden;
}


.person-header {
    padding: 1em;
    background: #36393F;
    border-top-left-radius: 0.2em;
    border-top-right-radius: 0.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.person-right {
    display: flex;
    flex-shrink: 0;
}

.person-right > * {
    padding: 0.5em;
} 

.person:not(.me) .person-right .material-icons {
    opacity: 0.7;
}

.person-right .material-icons.cursor-default {
    cursor: default;
}

.person-name  {
    font-weight: bold;
}

.person-status {
    font-size: 90%;
    word-break: break-word;
}

.person .video {
    display: flex;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    position: relative;
}

.person video {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
}

.person.me .person-header {
    cursor: pointer
}

.person.me .person-left:hover .person-status {
    font-weight: bold;
}


.overview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-flow: row;
}

.overview .room {
    cursor: pointer;
    flex-shrink: 0;
    padding: 1em;
    min-height: 400px;
}

.overview .room:hover {
    background: #2b2d32;
}


.loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 300%;
    opacity: 0.5;
}


.overview .people {
    display: flex;
    flex-direction: column;
    margin: 0.1em;
}

.tiled .people {
    margin: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-flow: row;
    align-items: flex-start;
}

.tiled .room-header {
    padding: 1.5em;
    display: flex;
}

.tiled .room-header .material-icons {
    margin-right: 0.5em;
}

.tiled .room-header:hover {
    background: #2b2d32;
}

.person .person-right {
    opacity: 0.5;
}

.tiled .person-right:hover {
    opacity: 1;
}



input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
}


/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: white;
  border-radius: 1px;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: white;
    border-radius: 1px;
}

.rooms-header {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.rooms-header:hover {
    background: #2b2d32;
}

.rooms-header .room-name {
    padding: 1.5em;
}

.rooms-header .room-name:not(.active) {
    opacity: 0.5;
}

.notifications {
    position: fixed;
    right: 2em;
    bottom: 2em;
}

.notifications .notification {
    background: #36393F;
    padding: 1em;
    margin: 0.5em;
    border-radius: 0.3em;
    font-size: 90%;
}

.SpotifyPlayer::-webkit-scrollbar {
    display: none;
}
